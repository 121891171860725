.widget-products {
    
    --color: var(--assertive);
    --bg: var(--black);
    --bg-card: var(--black);
    --color-card: var(--light);
    --color-date: var(--black);
    --bg-date: var(--white);
    --gap: var(--grid-gap);
    --height-scrollbar: var(--padding-s);
    --padding-bottom: var(--padding-xl);

    --font-size: var(--font-size-base);
    --color-title: var(--black);
    --bg-title: var(--white);

    @media (max-width: $smartphone) {

    }
}

.widget-products {
    color: var(--color);
    background-color: var(--bg);
    padding-bottom: var(--padding-bottom);
}

.widget-products__slider {
    position: relative;

    margin-left: calc(-1 * var(--padding-s));
    margin-right: calc(-1 * var(--padding-s));

    > .holder {
        position: relative;    
        display: flex;
        flex-wrap: nowrap;
        gap: var(--gap);
        overflow: hidden;

        cursor: grab;
        &:active { cursor: grabbing; }

        .__touch & {
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
            -webkit-scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }
        }
        
        padding: 0 var(--padding-s);            
    }
}

.widget-products__card {
    --width: 30vw;
    --height: calc(var(--width) * 1.25);
    --font-size: var(--font-size-base);
    --radius: #{rem(30)};

    @media (max-width: $smartphone) {
        scroll-snap-align: center;
        --width: 80vw;
        --font-size: var(--font-size-small);
        --radius: #{rem(20)};
    }
    //130

    position: relative;
    flex: 0 0 var(--width);
    width: var(--width);
    height: var(--height);
    background: var(--bg-light);
    user-select: none;

    a {
        position: relative;
        @include basic-a();
        display: block;
        width: 100%;
        height: 100%;
        user-select: none;
        -webkit-user-drag: none;
    }

    figure {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: var(--radius);
        overflow: hidden;
        
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;

            @media (max-width: $smartphone) {
                width: 100%;
            }
        }
    }

    .title {
        position: absolute;
        bottom:var(--padding-m);
        left:var(--padding-m);
        width: calc(100% - var(--padding-m) * 2);
        @include font-sans(1);
        font-size: var(--font-size);
        color: var(--color-title);
        background-color: var(--bg-title);
        border: 1px solid var(--color-title);
        padding: .3em 1.12em .14em;
        border-radius: .28em; 
        overflow: hidden;
    }
}

@media (max-width: $smartphone) {
    .widget-products__card {
        
    }

    .button-link {
        width: 100%;
    }
}

.widget-products__controls {
    margin-top: var(--padding-l);
    width: 100%;
    display: flex;
    justify-content: center;
    gap: var(--gap);

    > .scrollbar {
        position: relative;
        width: 50%;
        height: var(--height-scrollbar);
        border-radius: var(--height-scrollbar);
        overflow: hidden;
        background-color: var(--light);

        .track-thumb {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% * var(--progress));
            height: 100%;
            border-radius: var(--height-scrollbar);
            background-color: var(--assertive);
        }
    }

    @media (max-width: $smartphone) {
        display: none;
    }
}
.text-animation {
    --offset-top: .4em;
    --offset-bottom: .1em;
    --margin-first: calc(-1 * var(--offset-top));
    --margin: calc(-1 * (var(--offset-top) + var(--offset-bottom)));

    > div {
        overflow: hidden;
        margin: var(--margin) 0 0;
        padding: var(--offset-top) 0 var(--offset-bottom);

        &:first-of-type {
            margin: var(--margin-first) 0 0;
        }

        > div {
            transform-origin: var(--margin) var(--margin-first);
        }
    }
}
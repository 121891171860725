@use "sass:math";

.editorial-image-doble {
    --width-image: 50%;

    @media (max-width: $smartphone) {
        --width-image: 100%;
    }

    position: relative;
    width: 100vw;
    height: 100vh;
    display: flex;

    > figure {
        width: var(--width-image);
        height: 100%;
        padding: 0;
        position: relative;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

@media (max-width: $smartphone) {
    .editorial-image-doble {
        flex-direction: column;
    }
}
@use "sass:math";

.win-player {
  --color: white;

  --font-size-title: #{rem(48)};
  --font-size: #{rem(18)};
  --font-size-play: 20vw;
  --padding-v-header: var(--padding-s);
  --button-size: #{rem(72)};
  --padding-h-header: 50%;
   
  @media (max-width: $smartphone) {
    --font-size-title: #{rem(24)};
    --font-size: #{rem(16)};
    --font-size-play: 20vw;
    --button-size: #{rem(48)};
    --padding-v-header: var(--padding-s);
    --padding-h-header: #{rem(70)};
  }
}


.win-player {
  @include z-index($z-index-interface);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: var(--padding-v) var(--padding-h);
 
  color: var(--color);

  @media (max-width: $smartphone) {
   
  } 

  > header {
    @include z-index(3);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    border: 0;
    color: var(--color);
    pointer-events: none;
    padding: var(--padding-v-header) var(--padding-h-header) var(--padding-v-header) var(--padding-v-header);
    background: rgb(0,0,0);
    background: linear-gradient(180deg, rgba(0,0,0,1) 0%, rgba(0,0,0,0) 100%);
    /* DEGRADADO CON NEGRO ALPHA 1 arriba y negro alpha abajo*/

    
    .title {
      @include font-sans-bold(1);
      font-size: var(--font-size-title);
      text-transform: uppercase;
      
    }

    .author {
      @include font-sans(1);
      font-size: var(--font-size);
      text-transform: uppercase;
    }

    > .toggle {
      @include basic-a();
      position: absolute;
      width: var(--button-size);
      height: var(--button-size);
      top: var(--padding-s);
      right: var(--padding-s);
      border: 1px solid var(--color);
      border-radius: 50%;
      background: transparent;
      pointer-events: all;

      span {
        width: 100%;
        height: 100%;
        transform: rotate(45deg);

        svg {
          height: 100%;
          width: 100%;
          stroke: var(--color);
        }
      }
    }
  }

  > .bg {
    @include z-index(1);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    border: 0;
    background: black;
  }

  .player-holder {
    @include z-index(2);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    border: 0;

    display: flex;
    justify-content: center;
    align-self: center;

    --plyr-color-main: var(--white);

    > div {
      width: 100%
    }

    .plyr__control[data-plyr="play"] {
      background-color: transparent !important;
      padding: 0;
      opacity: 1;

      svg {
        display: none;
      }

      .plyr__sr-only {
        @include font-sans-bold(1);
        font-size: var(--font-size-play);
        -webkit-text-stroke: 1px white;
        text-stroke: 1px white;
        color: transparent;
        text-transform: uppercase;
        clip: none;
        border: 0!important;
        height: auto!important;
        overflow: hidden;
        padding: 0!important;
        position: relative!important;
        width: auto!important;
      }
    }

    .plyr__tooltip {
      display: none;
    }

    input[data-plyr="seek"]::-webkit-slider-thumb {
      border-radius: 0;
      box-shadow: 0;
    }

    input[data-plyr="seek"]::-moz-range-thumb {
      background: white !important; /* Green background */
      cursor: pointer; /* Cursor on hover */
    }

    .plyr__video-wrapper {
      height: auto !important;
    }
  }


  
}



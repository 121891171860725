#Sidemenu {
    --bg: var(--assertive);
    --color: var(--black);
    --font-size: var(--font-size-base);
    --font-size-title: #{rem(52)};
    --font-size-label: #{rem(24)};
    --font-size-link: #{rem(42)};
    --padding-v: calc(var(--header-height) + #{rem(30)});
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        --font-size-title: #{rem(32)};
        --font-size: #{rem(14)};
        --font-size-label: #{rem(14)};
        --font-size-link: #{rem(22)};
        --padding-v: var(--header-height);
    }
}

#Sidemenu header {
    --color: var(--color);
}

.sidemenu__content {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;

    @include terra-grid();

    padding: var(--padding-v) var(--padding-h);

    > .info {
        grid-column: 1 / span 2;

        .title {
            @include font-sans-bold(1.04);
            font-size: var(--font-size-title);
            letter-spacing: letter-spacing(-45);
            text-transform: uppercase;
            margin: 0 0 .4em;
        }

        p {
            @include font-sans(1);
            font-size: var(--font-size-base);
            text-transform: uppercase;
            margin: 0;
        }
    }

    > ul {
        grid-column: 3 / span 3;
    }
}

.sidemenu__content > ul li {
    @include basic-a();
    text-transform: uppercase;
    color: inherit;
    margin-bottom: 1em;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    
    .small {
        display: block;
        grid-column: 1 / 3;
        grid-row: 1 / 2;
        
        @include font-sans(1);
        font-size: var(--font-size-label);
        padding-bottom: .2em;
    }

    .text {
        grid-column: 1 / 3;
        grid-row: 2 / 3;

        @include font-sans-bold(1);
        font-size: var(--font-size-link);
        padding-right: 1em;
    }

    .button-link {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        align-self: flex-end;

        --color: var(--black);
        --bg: var(--bg);

        &:hover {
            --bg: var(--assertive);
        }
    }
}

#Sidemenu {
    @include z-index($z-index-sidemenu);

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;

    background-color: var(--bg);
    color: var(--color);
    font-size: var(--font-size);

    &[aria-expanded='false'] {
        visibility: hidden;
        pointer-events: none;
    }
}

@media (max-width: $smartphone) {
    .sidemenu__content {
        display: block;
        overflow-y: scroll;
        overflow-x: hidden;
        -webkit-overflow-scrolling: touch;
    }

    .sidemenu__content > ul {
        margin-top: 2em;
    }

    .sidemenu__content > ul li {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        margin-bottom: 3em;
        gap: 0;

        .small {
            width: 100%;
            margin-bottom: .3em;
        }

        .text {
            width: 100%;
        }

        .button-link {
            margin-top: 1em;
            width: 100%;
        }
    }

    #Sidemenu .footer-marca {
        --bg: var(--black);
        --color: var(--assertive);
    }
}
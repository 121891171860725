@mixin button-link {
    --width: auto;
    --height: #{rem(45)};
    --radius: #{rem(45)};
    --color: var(--primary-bg);
    --bg: var(--primary-color);
    --border-color: var(--color);
    --font-size: var(--font-size-base);
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-m);
    --min-width: 0;

    &.--big {
        --height: #{rem(80)};
        --radius: #{rem(80)};
        --font-size: #{rem(24)};
        --min-width: #{rem(200)};
    }
    
    @include font-sans(1);
    @include basic-a();

    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    padding: .2em var(--padding-h) 0;    
    appearance: none;

    background: var(--bg);
    border: 1px solid var(--color);
    border-radius: var(--radius);
    cursor: pointer;
    
    width: var(--width);
    height: var(--height);
    min-width: var(--min-width);
    
    font-size: var(--font-size);
    color: var(--color);
    text-align: center;
    vertical-align: top;
    text-transform: uppercase;

    * { pointer-events: none; }
    span { display: block; }

    @include isCursor() {
        &:hover {
            color: var(--bg);
            border-color: var(--bg);
            background-color: var(--color);
        }
    }

    &.--block {
        width: 100%;
    }

    &--alt {
        --bg: var(--white);
        --color: var(--black);
    }
}

.button-link {
    @include button-link;
}
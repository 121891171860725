.billboard-activity {
    --font-size-small: #{rem(30)};
    --font-size: #{rem(224)};
    --font-size-text: #{rem(24)};
    --padding-h: var(--padding-s);
    --padding-top: calc(var(--header-height) * 2.5);
    --padding-bottom: #{rem(180)};
    --top-bg: 0;
    --title-min-height: calc(100vh + var(--font-size) * .5);
    --margin: #{rem(90)};

    @media (max-width: $smartphone) {
        --font-size-small: #{rem(14)};
        --font-size: #{rem(62)};
        --font-size-text: #{rem(18)};
        --top-bg: 0;
        overflow: hidden;
        --padding-top: 0;
    }
}

.billboard-activity {
    display: grid;
    grid-template-columns: 1fr;
    margin-bottom: var(--margin);
    
    > .title {
        position: relative;
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        margin: calc(-1.4 * var(--font-size)) 0 0;
        padding: var(--padding-top) var(--padding-h) 0;
        text-transform: uppercase;
        color: var(--assertive);

        .small {
            display: block;
            @include font-sans(1);
            font-size: var(--font-small);
            margin-bottom: 1.2em;
        }

        .large {
            display: block;
            margin: 0 -.065em;
            @include font-sans-bold(.8);
            font-size: var(--font-size);
            letter-spacing: letter-spacing(-45);
        }

        &.--stroke {
            color: transparent;

            > .small {
                opacity: 1;
            }

            > .large {
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: var(--assertive);
                -webkit-text-fill-color: transparent;
            }
        }
    }

    > .bg {
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        position: relative;
        width: 100%;

        figure {
            position: relative;
            width: 100%;
            height: 80vh;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($color: #000000, $alpha: .1);
            }
        }

        img,
        video {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }

    > .text {
        grid-column: 1 / 2;
        grid-row: 3 / 4;

        color: var(--primary-color);
        padding: 0 var(--padding-h) 0;
        
        @include terra-grid();

        > .content {
            @include font-sans(1);
            font-size: var(--font-size-text);
            grid-column: 3/5;
            @media (max-width: $smartphone) {
                grid-column: 1 / 6;
            }
            align-self: center;
            text-transform: uppercase;

            p:not(:last-child),
            p:not(:first-child) {
                margin: 1em 0;
            }
        }
    }
}
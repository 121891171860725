@use "sass:math";

.header,
#Header {
    --width: 100%;
    --height: var(--header-height);
    --color: var(--secondary-bg);
    --bg: transparent;
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-s);
    --font-size: var(--font-size-base);

    @media (max-width: $smartphone) {
        --height: auto;
        --padding-v: var(--padding-s);
    }
}

.header,
#Header {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    height: var(--height);
    right: 0;
    top: 0;
    padding: var(--padding-v) var(--padding-h) var(--padding-s);

    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(1, var(--padding-l));
    gap: var(--grid-gap);

    color: var(--color);
    background-color: var(--bg);

    .logo {
        transform: translate3d(0, var(--y-header), 10px);
        height: 100%;
        color: inherit;
    }

    .logo svg {
        height: 100%;
        width: auto;
        fill: currentColor;
    }

    .header__nav__link {
        @include basic-a();
        @include font-sans(1.2);
        text-transform: uppercase;
        display: block;
        height: 100%;
        display: flex;
        padding: .15em 0 0;
        align-items: center;

        @include font-sans(1.2);
        font-size: var(--font-size);
        color: inherit;

        &::after {
            content: '.';
            margin: 0 .3em;
        }

        &::before {
            content: '.';
            margin: 0 .3em;
        }

        &.is-active {
            display: none;
        }
    }

    .toggle-menu {
        --size: #{rem(14)};
        @include font-sans(1.2);
        
        display: flex;
        align-items: center;
        gap: #{rem(3)};

        font-size: var(--font-size);
        color: inherit;
        background-color: transparent;
        padding: .15em 0 0;
        position: relative;

        > .cross {
            position: relative;
            display: block;
            width: var(--size);
            height: var(--size);
            transform-origin: center center;
    
            &::after,
            &::before {
                position: absolute;
                top:calc(#{rem(6)} - .07em);
                left:0;
                content: "";
                width: 100%;
                height: 1px;
                background-color: currentColor;
            }
            &::after {
                transform: rotate(90deg);
            }
        }

        &.--close {
            > .cross {
                transform: rotate(45deg);
            }
        }
    }
}

.header > .toggle-statistics,
#Header > .toggle-statistics {
    position: absolute;
    width: var(--grid-size);
    @include z-index($z-index-header);
    top: var(--padding-v);
    right: var(--padding-s);
}

.header > nav,
#Header > nav {
    transform: translate3d(0, var(--y-header), 10px);
    display: flex;
}

@media (max-width: $smartphone) {
  .header,
  #Header {
    display: flex;
    justify-content: space-between;

    .logo {
        width: 50%;
        height: auto;

        svg {
            width: 100%;
            height: auto;            
        }
    }

    > .toggle-statistics {
        display: none;
    }
  }
}

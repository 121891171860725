:root {
    --white: #e4f5f7;
    --black: #022737;
    --grey: #999;
    --light: #f9e4c7;
    --lighter: #fbf3e3;
    --assertive: #fcb36b;
    
    --primary: var(--black);
    --secondary: var(--white);

    --focus: #ff00ff;
    --color-error: #ff0000;
    --color-sucess: #4dc247;
}
.editorial-footer-link {
    --font-size: #{rem(24)};
    --margin: 2em;

    @media (max-width: $smartphone) {
        --font-size: #{rem(24)};
        --margin: 2em;
    }
}

.editorial-footer-link {
    width: 100%;
    @include font-sans-bold(1);
    font-size: var(--font-size);
    margin: var(--margin);
    text-align: center;
    text-transform: uppercase;

    a {
        color: var(--primary-color);
        text-decoration: underline;
    }
}
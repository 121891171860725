@use "sass:math";

:root {
  --y-header: 0;

  --padding-xl: #{math.div(90px, 16px) * 1rem};
  --padding-l: #{math.div(45px, 16px) * 1rem};
  --padding-m: #{math.div(30px, 16px) * 1rem};
  --padding-s: #{math.div(15px, 16px) * 1rem};
  --padding-xs: #{math.div(8px, 16px) * 1rem};
  --padding-xxs: #{math.div(8px, 16px) * 1rem};
  --padding-xxxs: #{math.div(4px, 16px) * 1rem};

  --header-height: #{math.div(90px, 16px) * 1rem};

  --grid-gap: #{math.div(15px, 16px) * 1rem};
  --grid-size: calc((100vw - var(--grid-gap) * 6)/5);
  
  --scrollbar-height: #{math.div(40px, 16px) * 1rem};
}

@mixin terra-grid() {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: var(--grid-gap);
}

@media (max-width: $smartphone) {
  :root {}
}

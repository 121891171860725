.block-legal {
    --font-size-title: #{rem(100)};
    --font-size-subtitle: var(--font-size-xlarge);
    --font-size-third: var(--font-size-large);
    --font-size: var(--font-size-base);
    --font-size-table: #{rem(11px)};
    --padding-v: calc(var(--header-height) * 2);
    --padding-h: var(--padding-l);
    --max-width: #{rem(900)};
    
    @media (max-width: $smartphone) {
        --font-size-title: #{rem(32)};
        --font-size-subtitle: var(--font-size-large);
        --font-size-third: var(--font-size-base);
        --font-size: var(--font-size-small);
        --padding-v: var(--padding-xl);
        --padding-h: var(--padding-s);
    }
}

.block-legal {
    @include font-sans();

    font-size: var(--font-size);
    padding: var(--padding-v) var(--padding-h);
    max-width: var(--max-width);
    margin: 0 auto;

    h1 {
        @include font-sans(1);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-l);
    }
    
    h2 {
        @include font-sans();
        font-size: var(--font-size-subtitle);
        margin: 1.2em 0 1em;
    }

    h3 {
        @include font-sans();
        font-size: var(--font-size-third);
        margin: 1.2em 0 1em;
    }

    p,
    ul {
        margin-bottom: var(--padding-s);
        max-width: rem(850);
        
        + h2 {
            margin-top: var(--padding-l);
        }
        
        + h3 {
            margin-top: var(--padding-m);
        }
    }

    ul {
        padding-left: var(--padding-s);

        li {
            list-style: initial;
        }
    }

    a {
        color: var(--primary-color);
        word-break: break-word;
    }

    table {
        font-size: var(--font-size-table);

        tr:nth-child(even) {background: #CCC}
        tr:nth-child(odd) {background: #FFF}
        color: var(--black);
        tr {
            padding: .2em .1em;
        }

        a {
            color: var(--black);
        }
    }

    @media (max-width: $smartphone) {
        h1 {
            margin-bottom: var(--padding-m);
        }
    }
}

@use "sass:math";

#Footer {
    --height: 100vh;
    --color: var(--assertive);
    --bg: var(--black);
    --font-size-title: #{rem(180)};
    --font-size-label: #{rem(28)};
    --font-size-legal: #{rem(12)};
    --font-size-hastag: #{rem(72)};
    --font-size-copy: #{rem(18px)};
    --height-hastag: #{rem(80)};
    --height-copy: #{rem(18)};
    --padding-v: 14vh;
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --height: auto;
        --font-size-title: #{rem(44)};
        --font-size-label: #{rem(18)};
        --font-size-copy: #{rem(11px)};
        --height-hastag: #{rem(48)};
        --font-size-hastag: #{rem(28)};
        --logo-width: #{rem(132)};
        --font-size: var(--font-size-xxsmall);
        --padding-h: var(--padding-s);
        --padding-v: var(--padding-s);
    }
}

#Footer {
    padding: var(--padding-v) var(--padding-h);
    background-color: var(--bg);
    color: var(--color);
    height: var(--height);

    .top {
        @include terra-grid();
        gap: 0;
    }

    .title {
        grid-column: 1 / 6;
        @include font-sans-bold(0.78);
        letter-spacing: letter-spacing(-45);
        font-size: var(--font-size-title);
        margin-bottom: var(--padding-xxs);
        text-transform: uppercase;
        margin: 0;
    }

    .label {
        @include font-sans(1);
        font-size: var(--font-size-label);
        grid-column: 1 / 2;
        text-transform: uppercase;
        align-self: center;
    }

    .hastag {
        @include font-sans(1);
        font-size: var(--font-size-hastag);
        line-height: var(--height-hastag);
        padding-top: .05em;
        grid-column: 2 / 5;
        text-transform: uppercase;
        height: var(--height-hastag);
        border-radius: var(--height-hastag);
        border: 2px solid var(--color);
        text-align: center;
        display: block;
    }

    .logos {
        grid-column: 5 / 6;
        align-self: center;
        padding: 0 var(--padding-s);
        text-align: right;

        svg {
            width: auto;
            height: var(--height-hastag);
            fill: var(--color);
        }
    }

    .bottom {
        position: absolute;
        padding: var(--padding-h);
        left: 0;
        bottom: 0;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
       
       .logo {
            width: var(--grid-size);

            svg {
                width: 100%;
                height: auto;
                fill: var(--color);
            }
       }

       .copyright {
            position: relative;
            @include font-sans();
            font-size: var(--font-size-copy);
            grid-column: 4 / 6;
            padding-left: 1.4em;
            text-transform: uppercase;
            justify-self: flex-end;

            svg {
                position: absolute;
                top: -.1em;
                left: 0;
                height: 100%;
                width: auto;
                stroke: var(--color);
            }

            a {
                font-weight: bold;
               
            }
       }

       a {
            @include basic-a();
            color: inherit;
       }

       .legales {
            @include font-sans(1);
            font-size: var(--font-size-legal);
            display: flex;
            justify-content: center;
            a {
                margin: 0 .4em;
            }
       }
    }

    @media (max-width: $smartphone) {
        .top {
            display: flex;
            flex-direction: column;
            gap: var(--padding-xs);

            .title {
                
            }

            .logos {
                width: 100%;
                display: flex;
                justify-content: center;
                padding: 20px 0;
        
                svg {
                    width:25%;
                    height: auto;
                }
            }
        }

        .bottom {
            position: relative;
            flex-wrap: wrap;
            margin-top: var(--padding-s);
            padding: 0;

            .legales {
                width: 100%;
                flex: 1 0 100%;
                order: 3;
                justify-content: space-between;
                padding: var(--padding-s) 0 0;
            }

            .logo {
                order: 2;
                width: #{rem(120)};
            } 

            .copyright {
                order: 1;
                width: #{rem(200)};
                padding-left: 3em;
            }
        }
    }
}

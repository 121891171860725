@use "sass:math";

.editorial-text {
    --cols: 2;
    --gap: var(--padding-xl);
    --font-size: #{rem(16)};
    --padding: var(--padding-xl);
    --max-width: #{rem(900)};

    @media (max-width: $smartphone) {
        --cols: 1;
        --font-size: var(--font-size-base);
        --padding: var(--padding-s);
    }
}

.editorial-text {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding: var(--padding);

    > .text {
        max-width: var(--max-width);
        @include font-sans();
        font-size: var(--font-size);
        column-count: var(--cols);
        column-gap: var(--gap);

        p {
            margin: .5em 0;

            &:first-child {
                margin-top: 0;
            }

            &:last-child {
                margin-bottom: 0;
            }
        }
    }
}
@use "sass:math";

.editorial {
    > figure {
        padding: 0;
        position: relative;
        width: 100vw;
        height: 100vh;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}
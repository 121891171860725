@import "utils/breakpoints";

@import "variables/colors";
@import "variables/paths";
@import "variables/fonts";
@import "variables/z-index";
@import "variables/ease";
@import "variables/sizes";

@import "utils/normalize";
@import "utils/normalize_cuchillo";
@import "utils/utils";
@import "utils/marquee";
@import "utils/mask";
@import "utils/scroll";
@import "utils/links";
@import "utils/cursor";
@import "utils/acordion";

@import "layout/header";
@import "layout/footer";
@import "layout/footer-form";
@import "layout/interface";
@import "layout/preloader";
@import "layout/scrollbar";
@import "layout/scroll-top";
@import "layout/sidemenu";

@import "links/link-arrow";

@import "blocks/block-billboard-home";
@import "blocks/block-text-images";
@import "blocks/block-text";
@import "blocks/block-section-default";
@import "blocks/block-talks";
@import "blocks/widget-activities";
@import "blocks/widget-products";
@import "blocks/block-casting";
@import "blocks/block-testimonios";
@import "blocks/block-slider-full";
@import "blocks/block-image-doble";
@import "blocks/block-video";
@import "blocks/block-image";
@import "blocks/block-number";
@import "blocks/block-blockquote";

@import "blocks/editorial-billboard";
@import "blocks/editorial-blockquote";
@import "blocks/editorial-gallery";
@import "blocks/editorial-image-doble";
@import "blocks/editorial-image";
@import "blocks/editorial-text";
@import "blocks/block-legal";

/*@import "blocks/block-accordions";
@import "blocks/block-slider-circular";
@import "blocks/block-slider-default";
@import "blocks/block-slider-horizontals-scroll";
@import "blocks/block-slider-horizontals-scroll-webgl";
@import "blocks/block-news-list";
@import "blocks/block-project-list";
@import "blocks/block-wysiwyg";*/

@import "blocks/block-player-video";


@import "modules/footer-marca";
@import "modules/button-link";
@import "modules/billboard-phase";
@import "modules/billboard-activity";
@import "modules/editorial-footer-link";

@import "modules/button";
@import "modules/custom-checkbox";
@import "modules/form";
@import "modules/next-project";
@import "modules/project-card";
@import "modules/text-animation";

@import "windows/cmp";
@import "windows/message";
@import "windows/statistics";
@import "windows/registration";
@import "windows/win-player";

@import "general";
@import "plyr.css";
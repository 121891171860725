.block-text {
    --font-size: #{rem(24)};
    --padding-v: #{rem(90)};
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: 0;
        --font-size: #{rem(18)};
    }
}

.block-text {
    @include terra-grid();
    margin: var(--padding-v) 0;
    padding: 0 var(--padding-h);

    .content {
        @include font-sans(1);
        font-size: var(--font-size);
        grid-column: 2/5;
        align-self: center;

        p:not(:last-child),
        p:not(:first-child) {
            margin: 1em 0;
        }
    }
}


@media (max-width: $smartphone) {
    .block-text {
        display: block;

        .content {
            padding: 0 var(--padding-s);
        }
    }
}
@use "sass:math";

.editorial-billboard {
    --font-size-title: #{rem(52)};
    --font-size: var(--font-size-base);
    --padding: var(--padding-s);
    --margin: 50vh;
    --image-width: 60vw;
    --image-height: min(var(--image-width), 80vh);

    @media (max-width: $smartphone) {
        --image-width: 80vw;
        --font-size-title: 10vw;
        --font-size: var(--font-size-base);
        --padding: var(--padding-s);
    }
}

.editorial-billboard {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: var(--margin);

    > figure {
        position: absolute;
        padding-top: 0;
        top: calc((100vh - var(--image-height)) / 2);
        left: calc((100vw - var(--image-width)) / 2);
        width: var(--image-width);
        height: var(--image-height);
        overflow: hidden;

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        &::after {
            position: absolute;
            content: '';
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }

    .title {
        @include font-sans(.85);
        position: relative;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
        padding: var(--padding);
   
        width: 100vw;
        height: 100vh;

        text-align: left;
        font-size: var(--font-size-title);
        letter-spacing: letter-spacing(-45);
        color: var(--primary-color);
        text-transform: uppercase;
    }
}
.block-section-default {
    --font-size-header: #{rem(18)};
    --font-size-title-header: #{rem(30)};
    --padding-v: 0;
    --padding-h: var(--padding-s);
    --max-width-text: 70%;
    --margin-title: 0;

    @media (max-width: $smartphone) {
        --font-size-title-header: #{rem(20)};
        --font-size-header: #{rem(14)};
        --max-width-text: 100%;
        --margin-title: .12em;
    }
}

.block-section-default {
    padding: var(--padding-v) var(--padding-h);
}

.block-section-default > header {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-end;
    padding: var(--padding-l) 0 calc(var(--padding-l) * .8);

    > * {
        max-width: var(--max-width-text);
    }

    .title {
        @include font-sans-bold(1);
        font-size: var(--font-size-title-header);
        text-transform: uppercase;
        margin-bottom: var(--margin-title);
    }

    .description,
    .text {
        @include font-sans(1);
        font-size: var(--font-size-header);

        a {
            font-weight: bold;
            @include basic-a();
            color: inherit;
            text-transform: uppercase;
            text-decoration: underline;
        }
    }

    .description {

    }
}

.block-section-default__footer {
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: flex-end;
    padding: var(--padding-l) 0 calc(var(--padding-l) * .8);

    > a {
       
    }
}
@use "sass:math";

.block-number {
    --height: 100vh;
    --bg: var(--black);
    --color: var(--assertive);
    
    --font-size-number: #{rem(600)};
    --font-size: #{rem(30)};

    --size-image: 20vw;
    --radius-image: calc(var(--size-image) * .03);
    --offset: calc(var(--size-image) * .8);

    @media (max-width: $smartphone) {
        --font-size-number: 40vw;
        --font-size: var(--font-size-base);

        --size-image: 30vw;
    }
}

.block-number {
    position: relative;
    width: 100%;
    height: var(--height);
    overflow: hidden;
    padding: var(--padding-s);

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    
    color: var(--color);
    background-color: var(--bg);

    .number {
        position: relative;
        @include z-index(2);
        display: block;
        height: var(--font-size-number);
        > span:first-child {
            position: absolute;
            font-size: 1px;
            left: -1000%;
            opacity: 0;
        }

        > .value {
            position: relative;
            display: block;
            @include font-sans();
            font-size: var(--font-size-number);
            letter-spacing: letter-spacing(-100);

            &::after {
                content: '%';
            }
        }
    }

    .text-holder {
        position: relative;
        @include z-index(4);
        @include terra-grid();
        width: 100%;

        @media (max-width: $smartphone) {
            display: block;
            text-align: center;
        }

        > .text {
            position: relative;
            grid-column: 3/5;
            @include font-sans();
            font-size: var(--font-size);
            text-transform: uppercase;
        }
    }

    figure {
        position: absolute;
        padding: 0;
        left:0;
        top:0;
        width: var(--size-image);
        height: var(--size-image);
    }

    img {
        position: absolute;
        left:0;
        top:0;
        width: 100%;
        height: auto;
        border-radius: var(--radius-image);
    }

    figure:nth-of-type(1) { left: 80%; top: calc(var(--offset) * -10); @include z-index(1);}
    figure:nth-of-type(2) { left: 5%; top: calc(var(--offset) * -9); @include z-index(3);}
    figure:nth-of-type(3) { left: 30%; top: calc(var(--offset) * -8); @include z-index(1);}
    figure:nth-of-type(4) { left: 55%; top: calc(var(--offset) * -7); @include z-index(1);}
    figure:nth-of-type(5) { left: 25%; top: calc(var(--offset) * -6); @include z-index(3);}
    figure:nth-of-type(6) { left: 90%; top: calc(var(--offset) * -5); @include z-index(3);}
    figure:nth-of-type(7) { left: 60%; top: calc(var(--offset) * -4); @include z-index(1);}
    figure:nth-of-type(8) { left: 30%; top: calc(var(--offset) * -3); @include z-index(3);}
    figure:nth-of-type(9) { left: 5%; top: calc(var(--offset) * -2); @include z-index(1);}
    figure:nth-of-type(10) { left: 80%; top: calc(var(--offset) * -1); @include z-index(1);}
    figure:nth-of-type(11) { left: 60%; top: calc(var(--offset) * 0); @include z-index(3);}
    figure:nth-of-type(12) { left: 25%; top: calc(var(--offset) * 1); @include z-index(3);}
    figure:nth-of-type(13) { left: 90%; top: calc(var(--offset) * 2); @include z-index(1);}
    figure:nth-of-type(14) { left: 55%; top: calc(var(--offset) * 3); @include z-index(3);}
    figure:nth-of-type(15) { left: 5%; top: calc(var(--offset) * 4); @include z-index(1);}
    figure:nth-of-type(16) { left: 30%; top: calc(var(--offset) * 5); @include z-index(1);}
    figure:nth-of-type(17) { left: 80%; top: calc(var(--offset) * 6); @include z-index(3);}
    figure:nth-of-type(18) { left: 90%; top: calc(var(--offset) * 7); @include z-index(3);}
    figure:nth-of-type(19) { left: 25%; top: calc(var(--offset) * 8); @include z-index(1);}
    figure:nth-of-type(20) { left: 60%; top: calc(var(--offset) * 9); @include z-index(3);}
    
    
}
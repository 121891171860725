@use "sass:math";

// Cookies
// -------------------------------


///VARS

.win-registration {
  --font-size-title: #{rem(60)};
  --font-size: #{rem(16)};
  --padding-top-form: var(--padding-m);
  --padding-v: var(--padding-s);
  --padding-h: var(--padding-s);
  --max-width: #{rem(880)};
  --bg: var(--light);
  --display-wrapper: flex;
  --width-cols: 50%;
  --margin-top-form: 0;

  @media (max-width: $smartphone) {
    --font-size-title: #{rem(32)};
    --font-size: var(--font-size-small);
    --display-wrapper: block;
    --width-cols: 100%;
    --padding-top-form: var(--padding-l);
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-s);

    overflow-y: scroll;
    overflow-x: hidden;
    -webkit-overflow-scrolling: touch;
  } 

  > .bg {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%; 
    border: 0;
    background: transparent;
  }

  .palette-white &,
  .palette-secondary &,
  .palette-primary & {
    --bg-color: var(--black);
    --color: var(--primary-color);
  }

  .palette-black & {
    --bg-color: var(--primary-color);
    --color: var(--black);
  }

  &.__success {
    --bg-color: var(--color-sucess);
    --color: var(--black);
  }

  &.__error {
    --bg-color: var(--color-error);
    --color: var(--white);
  }

  &[aria-expanded='false'] {
    visibility: hidden;
    pointer-events: none;
  }

  color: var(--color);
}

.win-registration {
  @include z-index($z-index-interface);
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  padding: var(--padding-v) var(--padding-h);

  @media (min-width: $smartphone) {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  cursor: auto;
  
  background-color: rgba($color: #000000, $alpha: .4);
}

.win-registration__wrapper {
  position: relative;
  width: 100%;
  height: auto;
  max-width: var(--max-width);
  padding: var(--padding-l) var(--padding-m);

  display: var(--display-wrapper);
  align-items: flex-start;
  justify-content: flex-start;
  gap: var(--grid-gap);
  
  background-color: var(--bg);
  border: 0;
  border-radius: #{rem(30)};

  .content {
    width: var(--width-cols);

    .title {
      @include font-sans-bold(.85);
      font-size: var(--font-size-title);
      margin: 0 0 .1em;
    }

    .text {
      @include font-sans(1);
      font-size: var(--font-size);
    }
  }

  form {
    width: var(--width-cols);
    padding-top: var(--padding-top-form);
  }
}

.block-testimonios {
    --color: var(--black);
    --bg: var(--white);
    --font-size: #{rem(52)};
    --font-size-name: var(--font-size-base);
    --font-size-role: var(--font-size-base);
    --width-image: calc(var(--grid-size) * .5);
    --height-image: calc(var(--width-image) * 1);
    --radius: 50%;
    --gap: var(--grid-gap);
    --padding-top: var(--padding-xl);
    --padding-h: calc(var(--padding-xl) * 2);
    --padding-bottom: var(--padding-xl);

    @media (max-width: $smartphone) {
        --font-size: #{rem(24)};
        --font-size-name: #{rem(11)};
        --font-size-role: #{rem(11)};
        --width-image: 40vw;
        --radius: #{rem(11)};
        --padding-top: var(--padding-m);
        --padding-bottom: var(--padding-m);
        --padding-h: var(--padding-s);
    }
}

.block-testimonios {
    color: vra(--color);
    background-color: var(--bg);
    padding: var(--padding-top) var(--padding-h) var(--padding-bottom);
}

.block-testimonios__ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
    
    li {
        grid-column: 1/2;
        grid-row: 1/2;
        
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        text-transform: uppercase;

        &[aria-hidden=true] {
            pointer-events: none;
            opacity: 0;
        }

        > figure {
            position: relative;
            width: var(--width-image);
            height: var(--height-image);
            border-radius: var(--radius);
            overflow: hidden;

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }

        > .text {
            position: relative;
            @include font-sans(1);
            font-size: var(--font-size);
            text-align: center;
            margin: 0.21em 0 .7em;
        }

        > .name {
            @include font-sans-bold(1);
            font-size: var(--font-size-name);
            text-align: center;
        }

        > .role {
            @include font-sans(1);
            font-size: var(--font-size-role);
            text-align: center;
        }
    }
}

.block-testimonios > .controls {
    position: absolute;
    top:0;
    left:0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: space-between;
    justify-items: center;
    
    > button {
        @include basic-a();
        @include font-sans(1);
        padding: 1em;
        border: 0;
        background-color: transparent;
        color: var(--color);
        font-size: var(--font-size);

        @media (max-width: $smartphone) {
            padding: 1em 0;
        }
    }
}
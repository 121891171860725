@use "sass:math";

.editorial-blockquote {
    --font-size: #{rem(52)};
    --padding: var(--padding-xl);
    --min-height: 80vh;
    
    @media (max-width: $smartphone) {
        --font-size: #{rem(32)};
        --padding: var(--padding-s);
        --min-height: 10px;
    }
}

.editorial-blockquote {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: var(--min-height);
    padding: var(--padding);
    @include font-sans(.85);
    font-size: var(--font-size);
    margin: 0;

    .text {
        margin-bottom: var(--padding);
        padding: 0 var(--padding);
        text-align: center;
    }
}
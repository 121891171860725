.block-slider-full {
    --width: 100vw;
    --height: 100vh;
    --height-controls: var(--padding-xl);
    --size-controls-btn: var(--padding-l);

    @media screen and (max-width: $smartphone) {
        --height: 80vw;
        --height-controls: var(--padding-l);
    }
}

.block-slider-full {
    width: var(--width);
    height: var(--height);
    background-color: var(--black);
    overflow: hidden;

    .holder {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        overflow: hidden;

        cursor: grab;
        &:active { cursor: grabbing; }

        .block-images-slider__item {
            position: relative;
            width: var(--width);
            height: var(--height);
            flex: 0 0 var(--width);
            overflow: hidden;

            img {
                position: absolute;
                top:0;
                left:0;
                width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                -webkit-user-drag: none;
                -khtml-user-drag: none;
                -moz-user-drag: none;
                -o-user-drag: none;
                user-drag: none;
            }
        }
    }
}

.block_slider_full__controls {
    position: absolute;
    bottom: var(--padding-s);
    width: 100%;
    height: var(--height-controls);
    display: flex;
    justify-content: center;
    align-items: center;
    gap: var(--grid-gap);
    color: var(--black);

    button {
        @include font-sans-bold(1);
        @include basic-a();

        width: var(--size-controls-btn);
        height: var(--size-controls-btn);

        appearance: none;
        background: transparent;
        border: none;
        padding: 1em;
        border-radius: 50%;
        background-color: var(--white);
    }

    .progress {
        --offset: #{rem(4)};
        @include font-sans(1);
        font-size: #{rem(18)};
        width: var(--height-controls);
        height: var(--height-controls);
        display: flex;
        justify-content: center;
        align-items: center;
        pointer-events: none;

        border-radius: 50%;
        background-color: var(--white);

        @media screen and (max-width: $smartphone) {
            display: none;
        }

        

        svg {
            position: absolute;
            top: var(--offset);
            left: var(--offset);
            width: calc(100% - var(--offset) * 2);
            height: calc(100% - var(--offset) * 2);
            fill: transparent;
            stroke-width: 2px;
            stroke: currentColor;
    
            .track {
                opacity: .3
            }
    
            .thumb {
                opacity: 1
            }
        }
    }
}
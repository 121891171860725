@use "sass:math";

.block-image-doble {
    --margin: #{rem(90)};
    --padding-h: var(--padding-s);

    @include terra-grid();
    width: 100%;
    padding: 0 var(--padding-h);
    margin: var(--margin) 0;

    > figure {
        grid-column: 1 / 4;
        width: var(--width-image);
        position: relative;
        
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }
    }
}

@media (max-width: $smartphone) {
    .block-image-doble {
        flex-direction: column;
    }
}
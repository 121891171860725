.block-text-images {
    --font-size: #{rem(24)};
    --padding-v: var(--padding-m);
    --padding-h: var(--padding-s);
    
    @media (max-width: $smartphone) {
        --padding-v: var(--padding-l);
        --padding-h: 0;
        --font-size: #{rem(18)};
    }
}

.block-text-images {
    @include terra-grid();
    padding: var(--padding-v) var(--padding-h) var(--padding-v);

    .content {
        @include font-sans(1);
        font-size: var(--font-size);
        grid-column: 3/5;
        align-self: center;

        p:not(:last-child),
        p:not(:first-child) {
            margin: 1em 0;
        }
    }

    .gallery {
        position: relative;
        grid-column: 1/3;
        height: 200vh;
        overflow: hidden;

        &::after,
        &::before {
            content: '';
            @include z-index(3);
            position: absolute;
            left: 0;
            width: 100%;
            height: 20vh;
            
        }

        /*&::after {
            top: 0;
            background: linear-gradient(var(--primary-bg), transparent);
        }

        &::before {
            bottom: 0;
            background: linear-gradient(transparent, var(--primary-bg));
        }*/

        .list {
            @include z-index(1);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: auto;

            display: flex;
            flex-direction: column;
            gap: var(--grid-gap);
        }
    }
}


@media (max-width: $smartphone) {
    .block-text-images {
        display: block;

        .content {
            padding: var(--padding-s);
        }
    
        .gallery {
            display: none;
        }
    }
}
.billboard-phase {
    --font-size-small: #{rem(30)};
    --font-size: #{rem(224)};
    --padding-h: var(--padding-s);
    --padding-top: calc(var(--header-height) * 2.5);
    --top-bg: 80vh;
    --title-min-height: calc(100vh + var(--font-size) * .5);

    @media (max-width: $smartphone) {
        --font-size-small: #{rem(14)};
        --font-size: #{rem(62)};
        --top-bg: 50vh;
        --title-min-height: 60vh;
        overflow: hidden;
    }
}

.billboard-phase {
    display: grid;
    grid-template-columns: 1fr;
    
    
    > .title {
        position: relative;
        grid-column: 1 / 2;
        grid-row: 1 / 2;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        min-height: var(--title-min-height);

        margin: 0;
        padding: var(--padding-top) var(--padding-h) 0;
        text-transform: uppercase;

        .small {
            display: block;
            @include font-sans(1);
            font-size: var(--font-small);
            margin-bottom: 1.2em;

            @media (max-width: $smartphone) {
                margin-bottom: .6em;
            }
        }

        .large {
            display: block;
            margin: 0 -.065em;
            @include font-sans-bold(.8);
            font-size: var(--font-size);
            letter-spacing: letter-spacing(-45);
        }

        &.--stroke {
            color: transparent;

            > .small {
                opacity: 0;
            }

            > .large {
                -webkit-text-stroke-width: 1px;
                -webkit-text-stroke-color: var(--primary-bg);
                -webkit-text-fill-color: transparent;
            }
        }
    }

    > .bg {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
        position: relative;
        //padding-top: var(--top-bg);
        width: 100%;
        margin-top: calc(-1.4 * var(--font-size));

        

        figure {
            position: relative;
            width: 100%;
            height: 100vh;
            overflow: hidden;

            &::after {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 100%;
                background: rgba($color: #000000, $alpha: .32);
            }
        }

        video {
            position: relative;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center center;
        }
    }
}
.block-casting {
    --color: var(--black);
    --bg: var(--white);
    --font-size-title: #{rem(190)};
    --font-size: #{rem(30)};
    --width-image: #{rem(250)};
    --height-image: calc(var(--font-size-title) * .82);
    --radius: #{rem(20)};
    --gap: var(--grid-gap);
    --padding-top: 0;
    --padding-bottom: var(--padding-m);

    @media (max-width: $smartphone) {
        --font-size-title: #{rem(140)};
        --width-image: #{rem(200)};
        --font-size: vra(--font-size-base);
    }
}

.block-casting {
    color: vra(--color);
    background-color: var(--bg);
    padding: var(--padding-top) var(--padding-s) var(--padding-bottom);
    padding-bottom: var(--padding-bottom);
}

.block-casting__ul {
    margin: 0 calc(-1 * var(--padding-s));

    li {
        position: relative;
        width: 100%;
        overflow: hidden;
        margin-bottom: var(--padding-m);
        padding-top: var(--font-size-title);

        > .item {
            position: absolute;
            top:0;
            left:0;
            height: var(--font-size-title);
            display: flex;
            flex-wrap: nowrap;
            gap: var(--gap);
        }

        figure {
            flex: 0 0 var(--width-image);
            width: var(--width-image);
            height: var(--height-image);
            position: relative;
            border-radius: var(--radius);
            overflow: hidden;

            img {
                position: absolute;
                top:0;
                left:0;
                width: 120%;
                height: 100%;
                object-fit: cover;
                object-position: center;
            }
        }
        
        .name,
        .surname {
            @include font-sans-bold(1);
            font-size: var(--font-size-title);
            letter-spacing: letter-spacing(-45);
            text-transform: uppercase;
        }

        .description {
            display: block;
            @include font-sans-bold(1);
            font-size: var(--font-size);
            padding: 0 var(--padding-s);
        }
    }
}

.block-casting__slider {
    position: relative;

    margin-left: calc(-1 * var(--padding-s));
    margin-right: calc(-1 * var(--padding-s));

    > .holder {
        position: relative;    
        display: flex;
        flex-wrap: nowrap;
        gap: var(--gap);
        overflow: hidden;

        cursor: grab;
        &:active { cursor: grabbing; }

        .__touch & {
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
            -webkit-scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }
        }
        
        padding: 0 var(--padding-s);            
    }
}

.block-casting__card {
    --width: 30vw;
    --height: calc(var(--width) * 1.25);
    --font-size: var(--font-size-base);
    --radius: #{rem(30)};

    @media (max-width: $smartphone) {
        scroll-snap-align: center;
        --width: 80vw;
        --font-size: var(--font-size-small);
    }
    //130

    position: relative;
    flex: 0 0 var(--width);
    width: var(--width);
    height: var(--height);
    background: var(--bg-light);
    user-select: none;

    > figure {
        position: relative;
        width: 100%;
        height: 100%;
        border-radius: var(--radius);
        overflow: hidden;
        
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 120%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
        }
    }

    .title {
        position: absolute;
        top: var(--top-title);
        width: 100%; 
        left: 0;
        text-transform: uppercase;
        padding: .25em var(--padding);
        color: var(--color-card);
        @include font-sans-bold(1);
        font-size: var(--font-size-title);
        white-space: break-spaces;
    }
}

@media (max-width: $smartphone) {
    .block-casting__card {
        .description,
        .title {
            position: relative;
            top: auto;
            left: auto;
        }

        .title {
            margin-top: .2em;
        }

        .logos {
            position: absolute;
            right: 0;
            width: 100%;
            bottom: var(--padding);
            display: flex;
            justify-content: center;
            
            svg {
                display: block;
                height: auto;
                width: 40%;
                margin: 0 auto;
                fill: var(--assertive);
            }
        }

        .date {
            
        }
    }

    .button-link {
        width: 100%;
    }
}

.block-casting__controls {
    margin-top: var(--padding-l);
    width: 100%;
    display: flex;
    justify-content: center;
    gap: var(--gap);

    > .scrollbar {
        position: relative;
        width: 50%;
        height: var(--height-scrollbar);
        border-radius: var(--height-scrollbar);
        overflow: hidden;
        background-color: var(--light);

        .track-thumb {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% * var(--progress));
            height: 100%;
            border-radius: var(--height-scrollbar);
            background-color: var(--assertive);
        }
    }

    @media (max-width: $smartphone) {
        display: none;
    }
}
#Preloader {
    --font-size: var(--font-size-s);
    --logo-width: 16vw;
    --color: var(--white);
    --bg: var(--black);

    @media (max-width: $smartphone) {
        --font-size: 4vw;
        --logo-width: 32vw;
    }
}

#Preloader {
    position: fixed;
    width: 100vw;
    height: 100vh;;
    top: 0;
    left: 0;
    
    @include z-index($z-index-loader);
    color: var(--color);
    background-color: var(--bg);
    
    > .content {
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        align-items: center;
        flex-direction: column;
        display: flex;
        justify-content: center;
        opacity: 0;
    }
    
    @include font-sans(1);
    font-size: var(--font-size);
    text-transform: uppercase;

    .logo {
        width: var(--logo-width);
        height: auto;

        svg {
            fill: var(--assertive);
        }
    }

    .progress {
        background-color: var(--grey);
        height: rem(1);
        margin-top: var(--padding-s);
        position: relative;
        width: rem(80);
    }
    
    .thumb {
        background-color: var(--white);
        height: 100%;
        left: 0;
        position: absolute;
        transform: scale3d(0, 1, 1);
        transform-origin: center left;
        width: 100%;
    }
}

@use "sass:math";

.block-blockquote {
    --font-size: #{rem(90)};
    --padding: var(--padding-m);
    --margin-top: 2em;
    --margin-bottom: 1.2em;
    
    @media (max-width: $smartphone) {
        --font-size: #{rem(48)};
        --padding: var(--padding-s);
    }
}

.block-blockquote {
    display: flex;
    padding: 0 var(--padding);
    @include font-sans-bold(.85);
    font-size: var(--font-size);
    margin: var(--margin-top) 0 var(--margin-bottom);

    .text {
        text-transform: uppercase;
    }
}
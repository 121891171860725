.block-billboard-home {
    --color: var(--assertive);
    
    width: 100vw;
    height: 100vh;

    .bg {
        video {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            object-fit: cover;
        }

        &::after {
            content: '';
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: rgba($color: #000000, $alpha: .23);
        }
    }
}


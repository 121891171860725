@use "sass:math";

.editorial-gallery {
    --height: 400vh;
    --height-holder: 100vh;

    @media (max-width: $smartphone) {
        --height: 60vh;
        --height-holder: var(--height);
    } 
}

.editorial-gallery {
    --width-image: 40vw;
    
    position: relative;
    width: 100%;
    height: var(--height);

    > .slider-holder {
        display: flex;
        justify-content: flex-start;
        width: 100%;
        height: auto;
    }

    @media (max-width: $smartphone) {
        overflow: hidden;
    }
}

.editorial-gallery__slider {
    position: relative;
    width: 100%;
    height: var(--height-holder);
    overflow: hidden;

    > .holder {
        position: relative;
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: flex-start;
    }

    img {
        user-select: none;
        -webkit-user-drag: none;
        pointer-events: none;
    }

    .__touch & {
        > .holder {
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
            -webkit-scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }
        }
    }
}

.editorial-gallery__card {
    position: relative;
    flex: 1 0 auto;
    height: 100%;
    overflow: hidden;

    .__touch & {
        scroll-snap-align: center;
    }
        

    figure {
        height: 100%;
        width: auto;
    }

    svg {
        height: 100%;
        width: auto;
    }

    img {
        position: absolute;
        top:0;
        left:0;
        width: 110%;
        height: 100%;
        object-fit: cover;
        object-position: center;
    }
}
.widget-activities {
    --color: var(--black);
    --bg: var(--white);
    --bg-card: var(--black);
    --color-card: var(--light);
    --color-date: var(--black);
    --bg-date: var(--white);
    --gap: var(--grid-gap);
    --height-scrollbar: var(--padding-s);
    --padding-bottom: var(--padding-xl);
}

.widget-activities {
    color: vra(--color);
    background-color: var(--bg);
    padding-bottom: var(--padding-bottom);
}

.widget-activities__slider {
    position: relative;

    margin-left: calc(-1 * var(--padding-s));
    margin-right: calc(-1 * var(--padding-s));

    > .holder {
        position: relative;    
        display: flex;
        flex-wrap: nowrap;
        gap: var(--gap);
        overflow: hidden;

        cursor: grab;
        &:active { cursor: grabbing; }

        .__touch & {
            overflow-x: scroll;
            overflow-y: hidden;
            white-space: nowrap;
            scroll-behavior: smooth;
            scroll-snap-type: x mandatory;
            -webkit-scroll-snap-type: x mandatory;

            &::-webkit-scrollbar {
                display: none;
            }
        }
        
        padding: 0 var(--padding-s);            
    }
}

.widget-activities__card {
    --mod: 1;
    --width: calc((100% - var(--gap) * 4)/3);
    --height: calc(#{rem(675)} * var(--mod));
    --height-img: calc(#{rem(405)} * var(--mod));
    --height-logos: calc(#{rem(30)} * var(--mod));
    --height-button: calc(#{rem(40)} * var(--mod));
    --width-button: calc(#{rem(180)} * var(--mod));

    --top-title: var(--height-img);
    --top-description: calc((var(--top-title) + var(--font-size-title) * 2.2) * var(--mod));

    --font-size: calc(#{rem(14)} * var(--mod));
    --font-size-date: calc(var(--font-size-base) * var(--mod));
    --font-size-button: calc(#{rem(18)} * var(--mod));
    --font-size-title: calc(#{rem(48)} * var(--mod));
    --padding: calc(var(--padding-s) * var(--mod));
    --padding-bottom: calc(var(--padding-s) * var(--mod));

    --radius: calc(#{rem(30)} * var(--mod));

    &.--video {
        --height-img: calc(#{rem(315)} * var(--mod));
        --top-description: calc((var(--top-title) + var(--font-size-title) * 3.3) * var(--mod));
    }

    @media (max-width: $smartphone) {
        scroll-snap-align: center;
        --width: 80vw;
        --height-img: 50vw;
        --height: 130vw;
        --radius: #{rem(20)};
        --padding: #{rem(10)};
        --top-title: calc(var(--height-img) + .2em);
        --font-size-title: #{rem(30)};
        --width-button: calc(100% - var(--padding) * 2);
        --padding-bottom: #{rem(62)};
        --radius: #{rem(20)};
    }
    //130

    position: relative;
    flex: 0 0 var(--width);
    width: var(--width);
    height: var(--height);
    background: var(--bg-card);
    border-radius: var(--radius);
    border: rem(4px) solid var(--bg-card);
    overflow: hidden;
    user-select: none;

    > figure {
        position: relative;
        width: 100%;
        height: var(--height-img);
        
        img {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            pointer-events: none;
        }

        figcaption {
            position: absolute;
            bottom: 0;
            right: 0;
            padding: .4em 1em;
            @include font-sans(1);
            font-size: 10px;
            color: white;
            text-transform: uppercase;
            text-shadow: 1px 1px 2px #000000;
        }
    }

    .title {
        position: absolute;
        top: var(--top-title);
        width: 100%; 
        left: 0;
        text-transform: uppercase;
        padding: .25em var(--padding);
        color: var(--color-card);
        @include font-sans-bold(1);
        font-size: var(--font-size-title);
        white-space: break-spaces;
    }

    .description {
        position: absolute;
        top: var(--top-description);
        width: 100%;
        left: 0;
        padding: 0 var(--padding);
        color: var(--color-card);
        @include font-sans(1.28);
        font-size: var(--font-size);
        white-space: break-spaces;
    }

    .date {
        position: absolute;
        display: none;
        top:0;
        left:0;
        @include font-sans(1);
        font-size: var(--font-size-date);
        color: var(--color-date);
        background-color: var(--bg-date);
        border: 1px solid var(--color-date);
        padding: .3em 1.32em .14em;
        border-radius: .28em;
        top: var(--padding);
        left:var(--padding);
    }

    .logos {
        position: absolute;
        right: var(--padding);
        bottom: var(--padding-bottom);
        
        svg {
            height: var(--height-logos);
            width: auto;
            fill: var(--assertive);
        }
    }

    .button-link {
        --bg: var(--assertive);
        --color: var(--black);
        --border-color: var(--assertive);

        position: absolute;
        left: var(--padding);
        bottom: var(--padding-bottom);
        width: var(--width-button);
        height: var(--height-button);
        font-size: var(--font-size-button);
        text-transform: uppercase;
    }
}

@media (max-width: $smartphone) {
    .widget-activities__card {
        .description,
        .title {
            position: relative;
            top: auto;
            left: auto;
        }

        .title {
            margin-top: .2em;
        }

        .logos {
            position: absolute;
            right: 0;
            width: 100%;
            bottom: var(--padding);
            display: flex;
            justify-content: center;
            
            svg {
                display: block;
                height: auto;
                width: 40%;
                margin: 0 auto;
                fill: var(--assertive);
            }
        }

        .date {
            
        }
    }

    .button-link {
        width: 100%;
    }
}

.widget-activities__controls {
    margin-top: var(--padding-l);
    width: 100%;
    display: flex;
    justify-content: center;
    gap: var(--gap);
    
    > .scrollbar {
        position: relative;
        width: 50%;
        height: var(--height-scrollbar);
        border-radius: var(--height-scrollbar);
        overflow: hidden;
        background-color: var(--light);

        .track-thumb {
            position: absolute;
            top: 0;
            left: 0;
            width: calc(100% * var(--progress));
            height: 100%;
            border-radius: var(--height-scrollbar);
            background-color: var(--assertive);
        }
    }

    @media (max-width: $smartphone) {
        display: none;
    }
}
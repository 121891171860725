@use "sass:math";


.toggle-statistics {
  --width: auto;
  --height: #{rem(45)};
  --radius: var(--height);
  --size-icon: #{rem(32)};
  --padding-h: #{rem(6)};

  --font-size-value: #{rem(38)};
  --font-size-label: #{rem(11)};

  @include basic-a();

  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;
  height: var(--height);

  @include font-sans(1);
  color: var(--secondary-bg);
  background-color: transparent;
  border-radius: var(--radius);
  border: 1px solid currentColor;

  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  
  .value {
    order: 2;
    height: 100%;
    flex: 0 0 50%;
    padding-top: .1em;
    padding-right: .6em;
    font-size: var(--font-size-value);
    letter-spacing: letter-spacing(-5);
    text-align: right;
  }

  .label {
    order: 1;
    flex: 0 0 calc(50% - var(--size-icon) - var(--padding-h));
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 1.5em;

    font-size: var(--font-size-label);
    letter-spacing: letter-spacing(-5);
    text-align: left;
    text-transform: uppercase;
  }

  .icon {
    order: 3;
    flex: 0 0 var(--size-icon);
    width: var(--size-icon);
    height: var(--size-icon);
    border-radius: var(--size-icon);
    border: 1px solid currentColor;
    fill: currentColor;

    svg {
      width: 100%;
      height: 100%;
      transform-origin: center center;
      transition: transform 0.6s var(--ease-in-out-quad);
    }
  }

  &.__close {
    .icon svg {
      transform: rotate(-135deg);
    }
  }
}

.win-statistics {
  position: fixed;
  top: calc(var(--header-height) - var(--padding-s));
  right: var(--padding-s);

  ul {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: block;
      margin: 2px 0;
    }
  }
}

.win-statistics__item {
  --width: var(--grid-size);
  --height: #{rem(45)};
  --radius: var(--height);
  --size-icon: #{rem(32)};
  --padding-h: #{rem(6)};

  --font-size-value: #{rem(38)};
  --font-size-label: #{rem(11)};

  @include basic-a();
  width: var(--width);
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0;

  @include font-sans(1);
  color: var(--secondary-bg);
  background-color: transparent;
  border-radius: var(--radius);
  border: 1px solid currentColor;

  background: rgba(255, 255, 255, 0.25);
  backdrop-filter: blur(5px);
  -webkit-backdrop-filter: blur(5px);
  
  .value {
    order: 2;
    height: 100%;
    flex: 0 0 50%;
    padding-top: .1em;
    padding-right: .6em;
    font-size: var(--font-size-value);
    letter-spacing: letter-spacing(-5);
    text-align: right;
  }

  .label {
    order: 1;
    flex: 0 0 calc(50% - var(--size-icon) - var(--padding-h));
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 1.5em;

    font-size: var(--font-size-label);
    letter-spacing: letter-spacing(-5);
    text-align: left;
    text-transform: uppercase;
  }
}



.win-statistics,
#Statistics {
  &[aria-expanded='false'] {
    visibility: hidden;
    pointer-events: none;
  }

  color: var(--color);
}


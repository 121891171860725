@use "sass:math";

.footer-form {
    --color: var(--black);
    --bg: var(--light);
    --bg-form: var(--lighter);
    --font-size-title: #{rem(140)};
    --font-size-label: var(--font-size-small);
    --font-size: #{rem(24)};
    --padding-v: var(--padding-xl);
    --padding-v-bottom: calc(var(--padding-xl) * 2);
    --padding-h: var(--padding-s);

    @media (max-width: $smartphone) {
        --font-size-title: #{rem(44)};
        --font-size: var(--font-size-small);
        --padding-v: var(--padding-l);
        --padding-h: var(--padding-s);
        --padding-v-bottom: var(--padding-l);
    }
}

.footer-form {
    width: 100vw;
    position: relative;
    padding: 90vh var(--padding-h) 0;
    background-color: var(--bg);

    z-index: 3;

    .wrapper-holder {
        
    }
    
    > .bg {
        position: absolute;
        top:0;
        left:0;
        width: 100%;
        height: 100%;
        overflow: hidden;

        figure {
            width: 100%;
            height: 100vh;
        }

        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center
        }
    }

    .footer-form__wrapper {
        color: var(--color);
        position: relative;
        @include terra-grid();
        padding: var(--padding-v) var(--padding-h) var(--padding-v-bottom);
        border-radius: #{rem(30)};
        background-color: var(--bg-form);
    }

    .footer-form__wrapper > .title {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
        @include font-sans-bold(0.84);
        letter-spacing: letter-spacing(-45);
        text-transform: uppercase;
        font-size: var(--font-size-title);
    }

    .footer-form__wrapper > .text {
        grid-column: 4 / 6;
        grid-row: 1 / 2;
        @include font-sans(1);
        text-transform: uppercase;
        font-size: var(--font-size);
        align-self: flex-end;
        padding-bottom: .65em;
        
    }

    .footer-form__wrapper > form {
        margin-top: var(--padding-xl);
        grid-column: 1 / 6;
        grid-row: 2 / 3;
        @include terra-grid();

        > .--double {
            grid-column: 4 / 6;
        }
    }
}


@media (max-width: $smartphone) {

    .footer-form {
        position: relative;
        padding: 30vh var(--padding-h) var(--padding-s);


    }

    .footer-form  > .bg {
        figure {
            height: 100%;

            img {
                object-position: center bottom
            }
        }
    }

    .wrapper-holder .footer-form__wrapper {
        display: flex;
        flex-direction: column;
        gap: 0;
    }

    .wrapper-holder .footer-form__wrapper > form {
        margin-top: var(--padding-m);
        display: flex;
        flex-direction: column;
        gap: var(--padding-xs);
        
    }
}
.footer-marca {
    --font-size-footer: #{rem(18px)};
    --height: #{rem(52)};
    --bg: transparent;
    
    position: absolute;
    width: 100%;
    @include font-sans();
    font-size: var(--font-size-footer);
    bottom: 0;
    padding: var(--padding-s);
    height: var(--height);
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    text-transform: uppercase;
    color: var(--color);
    background-color: var(--bg);

    .text {
        position: relative;
        padding-left: 1.4em;

        svg {
            position: absolute;
            top: -.1em;
            left: 0;
            height: 100%;
            width: auto;
            stroke: var(--color);
        }
    }

    a {
        @include basic-a();
        font-weight: bold;
        color: inherit;
        font-feature-settings: "salt" on;
    }

    .logos {
        width: auto;
        height: 100%;

        svg {
            height: 100%;
            width: auto;
            fill: var(--color);
        }
    }
}

@media (max-width: $smartphone) {
    .footer-marca {
        --font-size-footer: #{rem(11px)};

        .text {
            padding-left: 3em;
        }
    }
}
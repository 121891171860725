@use "sass:math";

.block-video {
    --font-size: #{rem(32)};
    --margin: #{rem(90)};
    --padding-h: var(--padding-s);
    --height: 80vh;
    
    margin: var(--margin) 0;
    padding: 0 var(--padding-h) 0;

    button {
        @include basic-a();
        position: relative;
        width: 100%;
        height: var(--height);
        background: transparent;
        border: 0;
        padding: 0 0 var(--padding-m);
        overflow: hidden;
        display: flex;
        justify-content: center;
        align-items: flex-end;

        img {
            position: absolute;
            top:0;
            left:0;
            width: 100%;
            height: 100%;
            object-fit: cover;
            object-position: center;
        }

        > span {
            position: relative;
            @include font-sans(1);
            font-size: var(--font-size);
            height: 15vh;
            width: 15vh;
            border-radius: 50%;
            display: flex;
            justify-content: center;
            align-items: center;
            color: var(--assertive);
            border: 2px solid var(--assertive);
        }

        &:hover > span {
            background: var(--assertive);
            color: var(--primary-bg);
        }
    }
}

@media (max-width: $smartphone) {
}
@use "sass:math";

.block-image {
    --margin: #{rem(90)};

    @include terra-grid();
    width: 100%;
    margin: var(--margin) 0;

    > figure {
        grid-column: 2 / 5;
    }
}

@media (max-width: $smartphone) {

}
.block-talks {
    --color: var(--primary-color);
    --font-size : var(--font-size-base);
    --font-size-name: var(--font-size-base);
    --font-size-title: #{rem(60)};
    --padding-item: var(--padding-l);

    @media (max-width: $smartphone) {
        --font-size : #{rem(14)};
        --font-size-name: var(--font-size-base);
        --font-size-title: #{rem(24)};
        --padding-item: var(--padding-s);
    }
}

.block-talks__ul {
    margin: 0;
    padding: 0;
    color: var(--color);

    li {
        margin: 0;
        padding: 0;

        &:first-of-type {
            border-top: 2px solid var(--color);
        }

        border-bottom: 2px solid var(--color);
        display: flex;
        align-items: flex-start;
        
        a,
        button {
            @include basic-a();
            @include terra-grid();
            color: inherit;
            padding: var(--padding-item) 0;
            background: transparent;
            outline: 0;

            @media (max-width: $smartphone) {
                display: block;
            }
        }

        .name {
            display: block;
            grid-column: 1/2;
            @include font-sans(1);
            font-size: var(--font-size-name);
            flex: 1;
            text-transform: uppercase;
            text-align: left;

            @media (max-width: $smartphone) {
                margin-bottom: .2em;
            }
        }

        .title {
            display: block;
            grid-column: 2/4;
            @include font-sans-bold(1);
            font-size: var(--font-size-title);
            margin-bottom: -.2em;
            flex: 1;
            text-transform: uppercase;
            text-align: left;

            @media (max-width: $smartphone) {
                margin-bottom: .2em;
            }
        }

        .description {
            display: block;
            grid-column: 4/6;
            @include font-sans(1);
            font-size: var(--font-size);
            padding-left: 2em;
            flex: 1;
            text-align: left;

            @media (max-width: $smartphone) {
                padding: 0;
            }
        }
    }
}